


@keyframes float {
	0% {
		box-shadow: 0 5px 5px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 5px 10px 10px 5px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 5px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}

.avatar {
	
	box-sizing: border-box;
	border: 0px ;
	border-radius: 50%;
	overflow: hidden;
	box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
	transform: translatey(0px);
	animation: float 8s ease-in-out infinite;
	
}

